<template>
	<div id='homeview'>
	<el-container style="min-height: 100vh">
		<el-aside :width="sideWidth"
			style="background-color: rgb(238, 241, 246); box-shadow: 2px 0 6px rgb(0 21 41 / 35%)">
			<el-menu :default-openeds="menuopens" style="min-height: 100% ; overflow-x: hidden"
				background-color="rgb(48,65,86)" text-color="#fff" active-text-color="#ffd04b"
				class="el-menu-vertical-demo" :collapse-transition="false" :collapse="isCollapse" router>
				<div style="height: 60px; line-height: 60px; text-align: center">
<!-- 					<img src="../assets/rgjlogo.png" alt=""
						style="width: 40px; position: relative; top: 5px; margin-right: 5px"> -->
					<b style="color: white" v-show="logTextShow">东部高速后勤审批</b>
				</div>
				<!-- 动态菜单 -->
				<!-- 在el-menu中引用了router,就可以在菜单的index中直接指向路由地址的path值，代替了openvue() -->
				<div v-for="item in menuid" :key="item.id_prikey" >
					<div v-if="item.pid==0 && item.menupath!=''">
						<el-menu-item :index="item.menupath" >
							<i :class="item.menuicon"></i>
							<span>{{item.menuname}}</span>
						</el-menu-item>
					</div>	
					<div v-else>
						<!-- 因为 item.id_prikey 是数值，所以加上一个空格转换成字符串-->
						<el-submenu :index="item.id_prikey + ''">  
							<template slot="title">
								<i :class="item.menuicon"></i>
								<span>{{item.menuname}}</span>
							</template>
							<div v-for="subitem in item.children" :key="subitem.id_prikey">
								<el-menu-item :index="subitem.menupath" @click="selectMenu(subitem)">
									<i :class="subitem.menuicon"></i>
									<span>{{subitem.menuname}}</span>
								</el-menu-item>								
							</div>
						</el-submenu>
					</div>
				</div>
			</el-menu>
		</el-aside>
		
		<el-container>
			<el-header
				style="text-align: left; font-size: 12px; border-bottom: 1px solid #ccc ; line-height: 60px; display: flex">
				<div style="flex: 1;font-size: 20px">
					<span :class="collapseBtnClass" style="cursor: pointer;font-size: 18px;" @click="collapse"></span>
					<el-breadcrumb style="display: inline-block;margin-left: 10px;">
						<el-breadcrumb-item :to="{ path: '/welcome' }" v-for="breadCrubitem in breadCrubList"
							:key="breadCrubitem.path">{{breadCrubitem.meta.title}}</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div class="header-avatar">
				<el-dropdown style="width: 500px;cursor: pointer;text-align: right;">
					<div style="display: inline-block;">
						<img :src="avatar_url" alt=""
							style="width: 30px;border-radius: 50%;position: relative; top 10px;right 5px" />
						<span>{{nickname}}</span>
						<i class="el-icon-arrow-down" style="margin-left: 5px"></i>
					</div>

					<el-dropdown-menu slot="dropdown" style="width:100px;text-align:center">
						<div @click="openvue('person','账号信息')">
							<el-dropdown-item>账号信息</el-dropdown-item>
						</div>
						<div @click="openvue('changpwd','修改密码')">
							<el-dropdown-item>修改密码</el-dropdown-item>
						</div>
						<div @click="openvue('enterpriseinfo','企业认证')">
							<el-dropdown-item>企业认证</el-dropdown-item>
						</div>
						<div @click="openvue('about','关于')">
							<el-dropdown-item>关于</el-dropdown-item>
						</div>
						<div @click="loginout">
							<el-dropdown-item>退出</el-dropdown-item>
						</div>

					</el-dropdown-menu>
				</el-dropdown>					
				</div>

			</el-header>
			<!-- tab标签页区域 - 用于标签页切换 -->
				<div class="tabs-switch-page">
				  <el-tag
				    size="medium"
				    v-for="(tab, index) in tabList"
				    :key="tab.name"
				    @close="handleClose(tab, index)"
				    @click="changeMenu(tab)"
				    :closable="tab.name !== 'welcome'"
				    :effect="activePath === tab.name ? 'dark' : 'plain'">
				  {{tab.label}}
				  </el-tag>
				</div>				
			<el-main>
				<!-- <router-view @refreshuser="getuser"/> -->
				<div style="padding:1px">
				  <!-- 注：include缓存的页面名称是vue文件中的name属性的值，不是路由的name,所以最好把这个值设置的与路由相同 -->
				  <keep-alive :include="catch_components">
				    <router-view @refreshuser="getuser"/>
				  </keep-alive>
				</div>
			</el-main>
		</el-container>
	</el-container>		
	</div>

</template>




<script>
	import { mapState, mapMutations } from 'vuex';
	import { isNull,treeDataTranslate } from "../assets/js/FormatTree.js";
	import { resetRouther } from "@/router"
	export default {
		name:'homeview',
		computed: {
			breadCrubList() {
				return this.$route.matched;
			},
			...mapState({ // 从 state 中的到的计算属性
			   activePath: state => state.activePath, // 已选中菜单
			   tabList: state => state.tabList,  // tags菜单列表
			   catch_components: state => state.catch_components,  // keepalive缓存
			}),
		},
		data() {
			const item = {
				date: '2016-05-02',
				name: '王小虎',
				address: '上海市普陀区金沙江路 1518 弄'
			};
			return {
				tableData: Array(10).fill(item),
				collapseBtnClass: 'el-icon-s-fold',
				isCollapse: false,
				sideWidth: "200px",
				logTextShow: true,
				headerBg: 'headerBg',
				username: "",
				nickname:"",
				avatar_url: "",
				menuid:[],		//动态菜单
				menuopens:[]   //用于展开菜单
			}
		},
		created(){
			this.username = window.sessionStorage.getItem("username");
			this.nickname = window.sessionStorage.getItem("nickname");
			this.getmenu();
			//console.log(this.menuid);
		},
		mounted: function() { //进入页面加载相当于VFP的init
			this.avatar_url = window.sessionStorage.getItem("avatar_url");
			//console.log(this.menuid)
			//console.log(this.$route);		
		},
		methods: {
			collapse() { //点击收缩按钮时触发
				this.isCollapse = !this.isCollapse
				if (this.isCollapse) { //收缩
					this.sideWidth = '60px'
					this.collapseBtnClass = 'el-icon-s-unfold'
					this.logTextShow = false
				} else { //展开
					this.sideWidth = '200px'
					this.collapseBtnClass = 'el-icon-s-fold'
					this.logTextShow = true
				}
			},
			openvue(vuefile,title) {
				//this.$router.push(vuefile)
				this.$store.commit('addKeepAliveCache', vuefile,)
				var submenu = {
				  path: vuefile,
				  name: vuefile,
				  label: title
				}
				//修改选中菜单
				this.$store.commit('selectMenu', submenu)
				this.$router.push({
					name: vuefile
				})
			},
			loginout() {
				window.sessionStorage.clear()
				
				this.$router.push({
					path: "/"
				})
				
				//重置路由
				resetRouther()
			},
			getuser(){
				var ctoken = window.sessionStorage.getItem("token");
				var cusername = window.sessionStorage.getItem("username");
				var that = this;
				this.$axios.get("https://gsoa.qiaokuaiji.com/wt_user.fsp?proc=getuser", {
						params: {
							key1: cusername,
							token: ctoken,
						}
					})
					.then(function(response) {
						//console.log(response);
						if (response.data.errno != 0) { //如果出错则显示后端错误
							that.$message.error(response.data.errmsg);
							return
						}
						that.avatar_url = response.data.rows[0].avatar_url;
						window.sessionStorage.setItem('avatar_url', that.avatar_url)
						//console.log(that.avatar_url)
					})
					.catch(function(error) {
						//console.log(error);
					});				
			},
			getmenu: function() { 
				//按用户查询菜单数据表的方法获取菜单
				// var that = this;
				// var ctoken = window.sessionStorage.getItem("token");
				// this.$request.get("/ctl_user.fsp?proc=getusermenu", { //利用了request.js中的baseURL
				// 		params: {
				// 			key1: this.username,
				// 			token: ctoken,
				// 		}
				// 	})
				// 	.then(res => {
				// 		//console.log(res);
				// 		if(res.errno!=0){
				// 			that.$message.error(res.errmsg);
				// 			return;
				// 		}
				// 		that.menuid=treeDataTranslate( res.table.rows, 'id_prikey','pid');
				// 		that.menuopens = that.menuid.map(v => v.id_prikey+'')  //由于id_prikey是数值，面在index中必须是字符，所以加一个空格转换成字符
				// 		//console.log(that.menuopens)
				// 		//console.log(this.menuid)
				// 		//console.log(this.menuid[0].children)
				// 	})
				//后改为用从login.vue中生成的sysmenu常量中获取菜单，减少一次后台交互
				const storeMenus = sessionStorage.getItem("sysmenu")
				this.menuid = JSON.parse(storeMenus)
				// console.log(JSON.parse(storeMenus))
				// console.log(this.menuid)
				//下面这句用于默认展开的菜单，如果启用则所有菜单展开
				//this.menuopens = this.menuid.map(v => v.id_prikey+'')
				// console.log(this.menuopens)
			},
			// 点击菜单 - 传入name，添加到keepalive缓存页面
			selectMenu(item){
			    // 加入keepalive缓存
			    this.$store.commit('addKeepAliveCache', item.menupath.replace("/", ""),)
			    //添加tags标签
			    //访问wellcome 就代表home
			    var name = item.menupath.replace("/", "") === 'welcome' ? 'home' : item.menupath.replace("/", "")
			    var submenu = {
			      path: name,
			      name: name,
			      label: item.menuname
			    }
			    //修改选中菜单
			    this.$store.commit('selectMenu', submenu)
			},
			// 关闭tab标签
			handleClose(tab, index) {
				//console.log(tab)
			    // 历史选中菜单
			    var oldActivePath = this.$store.state.activePath
			    // 历史已选中菜单列表
			    var oldTabList = this.$store.state.tabList
			    // 计算标签个数
			    let length = oldTabList.length - 1
			    // 删除tabList中的该对象
			    for(let i = 0;i < oldTabList.length;i++){
			        let item = oldTabList[i]
			        if(item.name === tab.name){
			        oldTabList.splice(i, 1);
			        }
			    }
			    // 删除keepAlive缓存
			    this.$store.commit('removeKeepAliveCache', tab.name)
			    // 如果关闭的标签不是当前路由的话，就不跳转
			    if (tab.name !== oldActivePath) {
			        return
			    }
			    // 如果length为1，必然只剩下首页标签，此时关闭后，更新到首页
			    if(length === 1){
			        // 同时存储菜单
			        this.$store.commit('closeTab', {activePath: 'home', tabList: oldTabList})
			        // tab页向左跳转
			        this.$router.push({ name: oldTabList[index - 1].name })
			        // 不再向下执行
			        return
			    }
			    // 关闭的标签是最右边的话，往左边跳转一个
			    if (index === length) {
			        // 同时更新路径
			        oldActivePath = oldTabList[index - 1].name
			        // 同时存储菜单
			        this.$store.commit('closeTab', {activePath:oldActivePath, tabList: oldTabList})
			        // tab页向左跳转
			        this.$router.push({ name: oldTabList[index - 1].name })
			    } else {
			        // 同时更新路径
			        oldActivePath = oldTabList[index].name
			        // 同时存储菜单
			        this.$store.commit('closeTab', {activePath:oldActivePath, tabList: oldTabList})
			        // tab页向右跳转
			        this.$router.push({ name: oldTabList[index].name })
			    }
			},
			// 点击标签跳转路由
			changeMenu(item) {
			    // 历史选中菜单
				//console.log(item)
			    var oldActivePath = this.$store.state.activePath
			    // 首先判断点击的是否是自己，如果是自己则return
			    if(oldActivePath === item.name){
			        return
			    }
			    // 不是自己，存储菜单
			    this.$store.commit('changeMenu', item.name)
			    // 页面跳转
			    this.$router.push({ name: item.name})
			}
		}
	};
</script>

<style>
	.headerBg {
		background-color: #f1efef !important;
	}
	.el-submenu__title:hover{
		background-color: rgb(38,52,69);
	}
	.el-menu-item:hover{
		background-color: rgb(38,52,69);
	}
	/* 解决左侧菜单收缩时文件不消失的问题 */
	.el-menu--collapse span{
		visibility: hidden;
	}
	.tabs-switch-page{
	  display: flex;
	  align-items:center;
	  height: 40px;
	  background-color:#fff;
	  overflow: hidden;
	  flex-wrap: wrap;
	  clear:both
	}
	.el-tag{
	  cursor: pointer;
	  margin-left: 10px;
	  border-radius: 2px;
	  font-size: 12px;
	  color: #1890FF;
	  border-color: #1890FF;
	}
	.el-tag--dark{
	  color: #fff;
	  background-color: #1890FF;
	}
	.header-avatar{
	  float:right;
	  width:40%;
	  display: flex;
	  align-items: center;
	  justify-content:flex-end;
	  padding-right:20px;
	  .user{
	    font-size: 14px;
	    font-weight: bold;
	    padding: 0 10px;
	  }
	}
	
	

</style>
