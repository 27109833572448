<template>
	<div id='login' class="login_container">
		<div style="height: 10%;text-align: center;font-size: 300%;margin-top:5%">
			江苏东部高速后勤审批系统
		</div>
		<div class="login_box">
			<!-- 头像区 -->
			<div class="avatar_box">
				<img src="../assets/dbgslogo.jpg" alt="" />
			</div>
			
			<!-- 登录区 -->
			<el-form ref="loginFormRef111" :model="loginForm" :rules="loginFormRules" label-width="0px"
				class="login_form">
				<!-- 用户名 -->
				<el-form-item prop="username">
					<el-input v-model="loginForm.username" prefix-icon="el-icon-user"></el-input>
				</el-form-item>
				<!-- 密码 -->
				<el-form-item prop="password">
					<el-input v-model="loginForm.password" prefix-icon="el-icon-lock" type="password" show-password>
					</el-input>
				</el-form-item>

				<el-form-item prop="code">
					<el-input type="text" auto-complete="false" style="width: 250px; margin-right: 5px;"
						v-model="loginForm.code" placeholder="点击图片更换验证码"></el-input>
					<div class="login-code" @click="refreshCode">
						<!--验证码组件-->
						<s-identify :identifyCode="identifyCode"></s-identify>
					</div>
				</el-form-item>

				<!-- 按钮 -->
				<el-form-item class="btns">
					<el-button type="primary" @click="login">登陆</el-button>
					<!-- <el-button type="info" @click="zhuche">注册</el-button> -->
					<!-- <el-button type="info" @click="resetLoginForm">重置</el-button> -->
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	import {
		isNull,
		treeDataTranslate
	} from "../assets/js/FormatTree.js";
	import {
		setRoutes
	} from "@/router";
	import SIdentify from '../components/sldentify';
	export default {
		name:'login',
		components: {
			SIdentify
		},
		data() {
			const validateCode = (rule, value, callback) => {
				if (this.identifyCode !== value) {
					this.loginForm.code = ''
					this.refreshCode()
					callback(new Error('请输入正确的验证码'))
				} else {
					callback()
				}
			}
			return {
				loginForm: {
					username: '',
					password: '',
					code: '',
					openid:"",
				},
				identifyCodes: '1234567890',
				identifyCode: '',
				checked: true,
				menuid: [],
				loginFormRules: {
					/* 验证用户名是否合法 */
					username: [{
							required: true,
							message: '请输入用户名',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 15,
							message: '长度在 2 到 15 个字符',
							trigger: 'blur'
						}
					],
					/* 验证密码 */
					password: [{
							required: true,
							message: '请输入密码',
							trigger: 'blur'
						},
						{
							min: 6,
							max: 15,
							message: '长度在 6 到 15 个字符',
							trigger: 'blur'
						}
					]
				},

			}
		},
		watch: {
			identifyCode(v) {
				this.isDebugLogin && (this.loginForm.code = v)
			}
		},
		methods: {
			resetLoginForm() {
				console.log(this)
				this.$refs.loginFormRef.resetFields()
			},
			openvue() {
				this.$router.push({
					name: "home",
				});
			},
			zhuche() {
				this.$router.push({
					name: "Register",
				});
			},
			login() {
				var that = this;
				if (this.loginForm.code !== this.identifyCode) {
					this.$message.error("验证码不正确！");
					return;
				};
				this.$axios.post("https://gsoa.qiaokuaiji.com/wt_login.fsp?proc=login", that.loginForm)
					.then(function(res) { //处理成功
						console.log(res)
						//console.log(res.data)
						if (res.data.errno != 0) { //如果出错则显示后端错误
							that.$message.error(res.data.errmsg);
							return
						}
						window.sessionStorage.setItem('username', that.loginForm.username)
						window.sessionStorage.setItem('token', res.data.token)
						window.sessionStorage.setItem('avatar_url', res.data.avatar_url)
						window.sessionStorage.setItem('nickname', res.data.nickname)

						//that.menuid=res.data.table.rows
						that.menuid = treeDataTranslate(res.data.table.rows, 'id_prikey', 'pid');
						console.log(that.menuid)
						window.sessionStorage.setItem('sysmenu', JSON.stringify(that.menuid))
						//动态设置当前用户的路由
						setRoutes()
						that.$message.success("登陆成功")
						console.log(res.data.rolename)
						if(res.data.rolename==="leadinguser"){
							that.$router.push("/front/home")
						}else{
							// that.$router.push("/")
							that.openvue()
						}
						
					})
					.catch(function(error) { //处理失败
						console.log(error)
					});
			},
			randomNum(min, max) {
				return Math.floor(Math.random() * (max - min) + min)
			},
			refreshCode() {
				this.identifyCode = ''
				this.makeCode(this.identifyCodes, 4)
			},
			makeCode(o, l) {
				for (let i = 0; i < l; i++) {
					this.identifyCode += this.identifyCodes[
						this.randomNum(0, this.identifyCodes.length)
					]
				}
				//console.log(this.identifyCode);
			},
		},
		created() {
			this.refreshCode()
		}
	}
</script>

<style Lang="less" scoped>
	.login_container {
		/* background-color: #2b4b6b; */
		height: 100%;
	}

	.login_box {
		width: 450px;
		height: 350px;
		background-color: #fff;
		border-radius: 3px;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	.avatar_box {
		height: 200px;
		width: 274px;
		/* border: 1px solid #eee; */
		/* border-radius: 50%; */
		/* padding: 10px; */
		/* box-shadow: 0 0 10px #ddd; */
		position: absolute;
		left: 50%;
		transform: translate(-50%, -70%);
		background-color: #fff;
	}

	img {
		height: 100%;
		width: 100%;
		/* border-radius: 50%; */
		/* background-color: #eee; */
	}

	.btns {
		display: flex;
		justify-content: flex-end;
	}

	.login_form {
		position: absolute;
		bottom: 0;
		width: 100%;
		padding: 0 20px;
		box-sizing: border-box;
	}

	.login-code {
		cursor: pointer;
		display: inline-block;

		.login-code-img {
			width: 100px;
			height: 38px;
			background-color: #eee;
			border: 1px solid #f0f0f0;
			color: #333;
			font-size: 18px;
			font-weight: bold;
			letter-spacing: 2px;
			text-indent: 2px;
			text-align: center;
		}
	}
</style>
